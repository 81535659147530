import * as React from 'react';
import Header from '../layouts/header-dark';
import Footer from '../layouts/footer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTag, faMinus, faExpand, faGlobe, faBalanceScale,
    faAngleDoubleDown, faInfo, faPhone, faMarker, faLaptop,
    faThumbsUp
} from '@fortawesome/free-solid-svg-icons';
import { Tabs, Tab, Row, Col, Nav, Form, Button } from 'react-bootstrap';
import '../styles/_bootstrap.scss';
import './index.scss';
import '../styles/_help.scss';

class Test extends React.PureComponent<{}, {}> {
    render() {
        let flexRow = { flexFlow: "row nowrap" as "row nowrap", display: "flex", width: 80 + "%", padding: 20 + "px" }
        let leftMargin = { padding: 25 + "px" }
        let textBoxStyle = { alignSelf: "center", padding: 40 + "px"}
        let imageStyling = { width: 500 + "px", height: 300 + "px" }
        return (
            <>
                <Header />
                <div className="page">
                    <div className="container-fluid hero">
                    </div>
                    <div className="container body">
                        <section className="section help dropship">
                            <h3><strong>What is Drop Shipping?</strong></h3>
                            <h4>Drop Shipping is a fulfilment method where the retailer purchases the products from a third party and has it shipped directly to their customer. <br /><br />
                                With the flexibility to range a variety of products whilst never handling the stock, this allows you as the retailer to focus your business around customer experience without the distraction of fulfilling your orders.</h4>
                        </section>
                        <section className="section help dropship">
                            <h3><strong>How does it work?</strong></h3>
                            <h4 className="text-center">Drop Shipping is simple and easy, take a look below and see for yourself.</h4>
                            <div className="row text-center" style={{ flexFlow: "column nowrap" as "column nowrap", display: "flex", width: 100 + "%" }}>

                                <div className="col-xs-4" style={flexRow}>
                                    <img src="/images/shop-online.jpg" alt="" className="img-responsive" style={{ ...imageStyling, ...leftMargin }} />
                                    <div style={textBoxStyle}>
                                        <h4>A customer purchases a product on your online store.</h4>
                                    </div>
                                </div>
                                <div className="col-xs-4" style={flexRow}>
                                    <div style={textBoxStyle}>
                                        <h4>You forward the order to us for processing and fulfilment.</h4>
                                    </div>
                                    <img src="/images/send_the_order.jpeg" alt="" className="img-responsive" style={{ ...imageStyling, ...leftMargin }} />
                                </div>
                                <div className="col-xs-4" style={flexRow}>
                                <img src="/images/shipped.jpg" alt="" className="img-responsive" style={{ ...imageStyling, ...leftMargin }} />
                                    <div style={{...textBoxStyle}}>
                                        <h4>We despatch the order directly to your customer on your behalf.</h4>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="section help dropship">
                            <h3><strong>How does drop shipping benefit you?</strong></h3>
                            <ul className="fa-ul">
                                <li><FontAwesomeIcon
                                    className="fa-li fa fa-balance-scale"
                                    icon={faTag}
                                    fixedWidth
                                    size="lg" />
                                    <h4><strong>White Label Solution</strong> Our white label technical support team are on hand to guide your customers through any issues or returns.</h4></li>
                                <li>
                                    <FontAwesomeIcon
                                        className="fa-li fa fa-balance-scale"
                                        icon={faMinus}
                                        fixedWidth
                                        size="lg" />
                                    <h4><strong>Less Capital Required</strong> No need to invest thousands into inventory. You only purchase products when there's an order to fulfil.</h4></li>
                                <li><FontAwesomeIcon
                                    className="fa-li fa fa-balance-scale"
                                    icon={faExpand}
                                    fixedWidth
                                    size="lg" />
                                    <h4><strong>Wider Product Selection</strong> Without the worry of inventory and pre-purchasing products, you are able expand your product line to offer a significant number of products to your customers.</h4>
                                </li>
                                <li><FontAwesomeIcon
                                    className="fa-li fa fa-balance-scale"
                                    icon={faGlobe}
                                    fixedWidth
                                    size="lg" />
                                    <h4><strong>Flexible Location</strong> As the order fulfilment is managed by us, you can run your business from anywhere.</h4>
                                </li>
                                <li><FontAwesomeIcon
                                    className="fa-li fa fa-balance-scale"
                                    icon={faBalanceScale}
                                    fixedWidth
                                    size="lg" />
                                    <h4><strong>Easy to Scale</strong> We fulfil the orders, making it easier to scale a drop shipping business.</h4>
                                </li>
                                <li><FontAwesomeIcon
                                    className="fa-li fa fa-angle-double-down"
                                    icon={faAngleDoubleDown}
                                    fixedWidth
                                    size="lg" />
                                    <h4><strong>Reduced Risk</strong> The risk of running an online business is dramatically reduced, with warehouse management handled by us.</h4>
                                </li>
                            </ul>
                        </section><section className="section help dropship text-center">
                            <h3><strong>Why dropship with us?</strong></h3>
                            <div className="help-actions row">

                                <div className="col-xs-2 col-xs-offset-1">
                                    <div className="dropship-action">
                                        <FontAwesomeIcon
                                            className="dropship-icon fa fa-info"
                                            icon={faInfo}
                                            fixedWidth
                                            size="lg" />
                                    </div>
                                    <h4><strong>Expert Staff</strong></h4>
                                </div>
                                <div className="col-xs-2">
                                    <div className="dropship-action">
                                        <FontAwesomeIcon
                                            className="dropship-icon fa fa-phone"
                                            icon={faPhone}
                                            fixedWidth
                                            size="lg" />
                                    </div>
                                    <h4><strong>Dedicated Support</strong></h4>
                                </div>

                                <div className="col-xs-2">
                                    <div className="dropship-action">
                                        <FontAwesomeIcon
                                            className="dropship-icon fa fa-map-marker"
                                            icon={faMarker}
                                            fixedWidth
                                            size="lg" />
                                    </div>
                                    <h4><strong>Centrally Located</strong></h4>
                                </div>
                                <div className="col-xs-2">
                                    <div className="dropship-action">
                                        <FontAwesomeIcon
                                            className="dropship-icon fa fa-laptop"
                                            icon={faLaptop}
                                            fixedWidth
                                            size="lg" />
                                    </div>
                                    <h4><strong>Invested in Technology</strong></h4>
                                </div>
                                <div className="col-xs-2">
                                    <div className="dropship-action">
                                        <FontAwesomeIcon
                                            className="dropship-icon fa fa-thumbs-up"
                                            icon={faThumbsUp}
                                            fixedWidth
                                            size="lg" />
                                    </div>
                                    <h4><strong>Automated Ordering</strong></h4>
                                </div>
                            </div>
                        </section>
                        <section className="section help dropship">
                            <div className="jumbotron cta-block text-center">
                                <h1 className="glyphicon glyphicon-comment"></h1>
                                <div className="cta-block__content">
                                    <h2 className="cta-block__heading">
                                        Join the UK's largest retailers who already have <br /> a head start! </h2>
                                    <h3 className="cta-block__heading">
                                        Speak to our team and we can show you how it works. </h3>
                                    <a className="btn btn-red btn-lg squared" href="mailto:web@direktek.co.uk">
                                        Talk to us</a>
                                </div>
                            </div>
                        </section>
                    </div>
                </div >
                <Footer />
            </>
        );
    }
}

export default Test; 